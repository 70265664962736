<template>
  <div
    class="h-screen flex w-full vx-row no-gutter items-center justify-center page"
    style="color: #626262"
  >
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-1/2 xl:w-1/3 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="card-bg page-card">
          <div v-if="!isLoading">
            <div style="margin-bottom: 25px">
              <img
                src="@/application/assets/images/logo/dnaskills.png"
                style="height: 128px; margin: auto"
              />
            </div>
            <div class="header-text">
              <h3>{{ $l.get("login.header") }}</h3>
            </div>
            <vs-alert
              :active="isUsernameAndPasswordInvalid"
              color="danger"
              style="text-align: center"
              >{{ $l.get("login.invalid-username-and-password") }}</vs-alert
            >
            <vs-alert
              :active="isPermissionInvalid"
              color="danger"
              style="text-align: center"
              >{{ $l.get("login.invalid-permissions") }}</vs-alert
            >
            <!-- <div class="centerx"> -->
            <form @submit.prevent="onLoginSubmit">
              <vs-input
                :label-placeholder="$l.get('dictionary.username', 'capitalize')"
                :danger="isUsernameInvalid"
                :danger-text="$l.get('errors.input-required', 'capitalize')"
                v-model="username"
              />
              <vs-input
                :label-placeholder="$l.get('dictionary.password', 'capitalize')"
                :danger="isPasswordInvalid"
                :danger-text="$l.get('errors.input-required', 'capitalize')"
                type="password"
                v-model="password"
              />

              <!-- <div style="margin-top: 25px; margin-bottom: 25px;">            
            <vs-checkbox>{{ $l.get("login.remember-checkbox") }}</vs-checkbox>            
              </div>-->

              <div style="margin-top: 25px; margin-bottom: 25px">
                <vs-button
                  color="primary"
                  type="filled"
                  @click="onLoginSubmit"
                  >{{ $l.get("login.submit-button") }}</vs-button
                >
              </div>
              <input
                type="submit"
                style="position: absolute; left: -9999px; width: 1px; height: 1px;"
                tabindex="-1"
              />
            </form>
          </div>
          <div
            class="animated fadeIn"
            style="text-align: center !important"
            v-else
          >
            <Loader :label="$l.get('login.loader', 'capitalize')" />
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import InitLoginScreenInteractor from "@/business/login/init-login-screen";
import SubmitLoginInteractor from "@/business/login/submit-login";
import LoginScreenController from "@/adapters/controllers/screen-login";
import { UserSignIn } from "@/domain/users/models";

import Loader from "@/application/components/Loader";

export default {
  screen_name: "login",
  components: { Loader },
  data() {
    return {
      controller: null,
      interactors: {
        initLoginScreen: null,
        selectSignUp: null,
        selectForgotPassword: null,
        submitLogin: null
      },
      data: null,
      username: "",
      password: "",
      isLoading: false,
      isUsernameInvalid: false,
      isPasswordInvalid: false,
      isUsernameAndPasswordInvalid: false,
      isPermissionInvalid: false
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(LoginScreenController);

    //{ INTERACTORS
    this.interactors.submitLogin = this.$injector.get(SubmitLoginInteractor);
    this.interactors.initLoginScreen = this.$injector.get(
      InitLoginScreenInteractor
    );
    //} INTERACTORS

    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.clearFormErrors = this.clearFormErrors;
    this.controller.setAsUsernameInvalid = this.setAsUsernameInvalid;
    this.controller.setAsPasswordInvalid = this.setAsPasswordInvalid;
    this.controller.setAsUsernameAndPasswordInvalid = this.setAsUsernameAndPasswordInvalid;
    this.controller.setAsPermissionInvalid = this.setAsPermissionInvalid;
  },
  mounted() {
    this.interactors.initLoginScreen.handle();
  },
  methods: {
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    clearFormErrors() {
      this.isUsernameInvalid = false;
      this.isPasswordInvalid = false;
      this.isUsernameAndPasswordInvalid = false;
      this.isPermissionInvalid = false;
    },
    setAsUsernameInvalid() {
      this.isUsernameInvalid = true;
    },
    setAsPasswordInvalid() {
      this.isPasswordInvalid = true;
    },
    setAsUsernameAndPasswordInvalid() {
      this.isUsernameAndPasswordInvalid = true;
    },
    setAsPermissionInvalid() {
      this.isPermissionInvalid = true;
    },
    onLoginSubmit() {
      this.interactors.submitLogin.handle(
        new UserSignIn(this.username, this.password),
        this.$route.query.next
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.card-bg .vs-con-input-label {
  width: 100% !important;
  margin-top: 25px;
}
.card-bg .vs-button {
  width: 100% !important;
}
.header-text {
  margin-top: 30px;
  margin-bottom: 40px;
  text-align: center;
}
.inputs .vs-input {
  margin: 15px;
}
</style>
